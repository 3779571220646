/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-11-14 16:55:42
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-16 17:25:32
 */
import React, { useContext, useEffect, useState } from "react"
import MdWrapper from "../../../../../components/MarkdownWrap"

import { useLocation } from "@reach/router"
import Axios from "axios"


const Privacy = () => {
  const [foo, setFoo] = useState(null)
  const location = useLocation()


  useEffect(() => {
    document.documentElement.lang = "en"
    getMd()

  }, [])
  const getMd = () => {
    Axios.get(`${process.env.resourceUrl}docs/nebula_h5_docs/tos/kddi/ToS-EN.md`).then(function (data) {
      setFoo(data.data)
    })

  }
  return (
    // <Layout isDocPage={false} menuBlack={true}>
    //   <SEO page="Privacy Policy" />
    <MdWrapper md={foo} blankLayout />
    // </Layout>
    // <div style={{ backgroundColor: "#f4f7f8 !important" }} />
  )
}

export default Privacy
